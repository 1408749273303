import Layout from "common/components/layout";
import {useParams, useNavigate, useLocation} from "react-router";
import {toast, ToastContainer} from "react-toastify";
import {set, useFieldArray, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import axios from "axios";
import {ApiUrl} from "API/config";
import SpreadsheetCommon from "../common/spreadsheet";
import dataExcel from "../common/demoxl";
import Loader from "common/components/loader";
const Option = {
  mode: "edit", // edit | read
  showToolbar: true,
  showGrid: true,
  showBottomBar: false,
  showContextmenu: true,
  view: {
    height: () => document.documentElement.clientHeight,
    width: () => document.documentElement.clientWidth - 550,
  },
  row: {
    len: 50,
    height: 25,
  },
  col: {
    len: 26,
    width: 100,
    indexWidth: 60,
    minWidth: 60,
  },
  style: {
    bgcolor: "#ffffff",
    align: "left",
    valign: "middle",
    textwrap: false,
    strike: false,
    underline: false,
    color: "#0a0a0a",
    font: {
      name: "Helvetica",
      size: 10,
      bold: false,
      italic: false,
    },
  },
};

function CapacityProductDetails() {
  const navigate = useNavigate();
  const op = useParams().op;
  const location = useLocation();
  const [read, setRead] = useState(false);
  const [excelSheetData, setExcelSheetData] = useState();
  const {register, control, handleSubmit, errors, formState, reset} = useForm();

  const [isLoader, setIsLoader] = useState(false);
  const onSubmit = (data) => {
    setIsLoader(true);
    console.log("save data", data);

    axios
      .post(`${ApiUrl}capacity/capacity/create`, {
        ...data,
        protegeId: location.state.data.protegeId,
        excelData: excelSheetData,
      })
      .then((result) => {
        console.log(result);
        toast("Saved Successfully ", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            navigate(-1);
          },
        });
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
        toast(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  let capacityId = location.state.data.capacityId && location.state.data.capacityId;
  useEffect(() => {
    if (capacityId) {
      getCapacity();
    }
  }, []);

  const getCapacity = () => {
    setIsLoader(true);
    axios
      .get(`${ApiUrl}capacity/capacity/${capacityId}`)
      .then((resultSku) => {
        setExcelSheetData(resultSku.data.excelData);
        setTimeout(() => setIsLoader(false), 400);
      })
      .catch((error) => {
        setIsLoader(false);
      });
  };

  return (
    <Layout>
      <ToastContainer />
      <div className="row">
        <div className="col-6">
          <h4 className="fw-bold py-3 mb-4">
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-2)}>Protégé</a> /
            </span>
            <span className="text-muted fw-light">
              <a onClick={() => navigate(-1)}>{op} Protégé</a> /
            </span>{" "}
            Notes
          </h4>
        </div>
      </div>
      {isLoader ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="mb-4">
            <div className="card-body">
              <form id="formAccountSettings" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-md-12 mb-3">
                  <p>Worksheet</p>
                  {excelSheetData && (
                    <SpreadsheetCommon
                      height={"100%"}
                      width={"100%"}
                      setExcelSheetData={setExcelSheetData}
                      options={
                        read
                          ? {
                              mode: "read",
                              showToolbar: false,
                              showGrid: false,
                              showContextmenu: false,
                            }
                          : Option
                      }
                      data={excelSheetData ? excelSheetData : dataExcel}
                    ></SpreadsheetCommon>
                  )}
                  {!excelSheetData && (
                    <SpreadsheetCommon
                      height={"100%"}
                      width={"100%"}
                      setExcelSheetData={setExcelSheetData}
                      options={
                        read
                          ? {
                              mode: "read",
                              showToolbar: false,
                              showGrid: false,
                              showContextmenu: false,
                            }
                          : Option
                      }
                      data={dataExcel}
                    ></SpreadsheetCommon>
                  )}
                </div>

                <div className="mt-5">
                  <hr />
                  <button type="submit" className="btn btn-primary me-2">
                    Save changes
                  </button>
                  <button type="reset" onClick={() => navigate(-1)} className="btn btn-outline-secondary">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default CapacityProductDetails;
